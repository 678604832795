import { template as template_d82e20ad0fcd444bba7b7e9948669a3e } from "@ember/template-compiler";
const WelcomeHeader = template_d82e20ad0fcd444bba7b7e9948669a3e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

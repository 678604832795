import { template as template_7c89f326d6a04bb6b0e1e79ee0e324b0 } from "@ember/template-compiler";
const FKLabel = template_7c89f326d6a04bb6b0e1e79ee0e324b0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_84ec583ef9664cd09c5c833a31fc3139 } from "@ember/template-compiler";
const FKControlMenuContainer = template_84ec583ef9664cd09c5c833a31fc3139(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
